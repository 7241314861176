import React, { useContext, useState, useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { doc, getDoc, updateDoc, setDoc, query, where, collection, getDocs, onSnapshot, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../firebase";
import { DarkModeContext } from '../context/darkModeContext';
import { useUserData } from "../lib/userData";
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const Subscribe = ({ cost, producerId }) => {
    const userId = auth.currentUser ? auth.currentUser.uid : 'null';
    const user = auth.currentUser;
    const navigate = useNavigate();
    const userData = useUserData();
    const { darkMode } = useContext(DarkModeContext);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [daysLeft, setDaysLeft] = useState(0);
    const [prePaidCode, setPrepaidCode] = useState("");
    const [purchasing, setPurchasing] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [codeData, setCodeData] = useState(null);

    useEffect(() => {
        const checkSubscription = async () => {
            if (!userData) return;

            try {
                const subscriptionsQuery = query(
                    collection(db, 'subscriptions'),
                    where('producerId', '==', producerId),
                    where('userId', '==', userId)
                );

                const unsubscribe = onSnapshot(subscriptionsQuery, (querySnapshot) => {
                    if (!querySnapshot.empty) {
                        querySnapshot.forEach(async (doc) => {
                            const subscriptionData = doc.data();
                            const expireDate = subscriptionData.expireDate.toDate();
                            const currentTime = new Date();

                            if (expireDate < currentTime) {
                                // Expire date has passed, update the expire field to true
                                await updateDoc(doc.ref, { expire: true });
                                setIsSubscribed(false);
                            } else {
                                setIsSubscribed(true);
                                const remainingTime = Math.ceil((expireDate - currentTime) / (1000 * 60 * 60 * 24)); // Calculate remaining days
                                setDaysLeft(remainingTime);
                            }
                        });
                    } else {
                        setIsSubscribed(false);
                    }
                });

                return () => {
                    unsubscribe();
                };
            } catch (error) {
                console.error("Error checking subscription: ", error);
            }
        };

        checkSubscription();
    }, [producerId, userId, userData]);

    const incrementExpiration = (currentTime) => {
        const expirationTime = new Date(currentTime);
        expirationTime.setDate(expirationTime.getDate() + 30);
        return expirationTime;
    };

    const handleAuth = () => {
        const currentLocation = `/producer/${producerId}`;
        localStorage.setItem('prevLocation', currentLocation);
        navigate('/login');
    };

    const handleSubscribe = async () => {
        setPurchasing(true);

        const codeInput = prePaidCode;

        if (!codeInput) {
            toast.error('No code entered');
            setPurchasing(false);
            return;
        }

        try {
            const docRef = doc(db, 'prePaidCodes', codeInput);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const docData = docSnap.data();
                setCodeData(docData);
                const { code, expire, value, currency } = docData;

                if (code === codeInput) {
                    if (expire !== 'yes') {
                        if (value === cost) {
                            if (currency === 'TZS') {
                                await updateDoc(docRef, {
                                    expire: 'yes',
                                    expireDate: serverTimestamp(),
                                    used: 'true',
                                    user: userId,
                                    content: 'subscription',
                                    creditedTo: [{ 'producer': producerId, earned: '50%' }, { 'company': 'iTrood, Inc', earned: '50%' }],
                                });

                                nowPurchase(docData, 'Pre-Paid Code', currency, value);
                                setSelectedMethod(null);
                            } else {
                                toast.error("Currency doesn't match, code can't purchase");
                            }
                        } else {
                            toast.error("Insufficient balance, code can't purchase");
                        }
                    } else {
                        toast.error('Expired code, It has been used');
                    }
                } else {
                    toast.error('Code mismatch');
                }
            } else {
                toast.error('Invalid or code not found');
            }
        } catch (error) {
            toast.error(`Error processing payment: ${error.message}`);
        } finally {
            setPurchasing(false);
        }
    };

    const nowPurchase = async (docData, selectedMethod, currency, value) => {
        if (!userData) {
            setError("You need to be logged in to subscribe.");
            handleAuth();
            return;
        }

        setLoading(true);
        setError("");
        setSuccess("");

        try {
            const subscriptionRef = doc(db, 'subscriptions', `${producerId}_${userId}`);
            const subscriptionDoc = await getDoc(subscriptionRef);

            const currentTimestamp = serverTimestamp();

            if (subscriptionDoc.exists()) {
                const subscriptionData = subscriptionDoc.data();
                if (subscriptionData.expire && subscriptionData.expire === true) {
                    await updateDoc(subscriptionRef, {
                        expire: false,
                        timestamp: currentTimestamp,
                        cost: parseFloat(cost),
                        expireDate: incrementExpiration(new Date()),
                        transactionID: docData.transactionID,
                        paymentGateway: 'Pre-Paid Code',
                        currency: 'TZS',
                        userName: userData.displayName,
                    });
                    setSuccess("Subscribed successfully!");
                    setIsSubscribed(true);
                } else {
                    setError("You are already subscribed to this producer.");
                    setLoading(false);
                    return;
                }
            } else {
                await setDoc(subscriptionRef, {
                    userId: userData.id,
                    producerId: producerId,
                    timestamp: currentTimestamp,
                    expire: false,
                    cost: parseFloat(cost),
                    expireDate: incrementExpiration(new Date()),
                    transactionID: docData.transactionID,
                    paymentGateway: 'Pre-Paid Code',
                    currency: 'TZS',
                    userName: userData.displayName,
                });
                setSuccess("Subscribed successfully!");
                setIsSubscribed(true);
            }

            const iTroodEarnings = (cost * 0.5).toFixed(2);
            const sellerEarnings = (cost * 0.5).toFixed(2);

            const earningsRef = doc(db, 'earnings', docData.transactionID);
            const earningsData = {
                buyer: userData.id,
                currency: currency,
                iTroodEarnings: iTroodEarnings,
                id: docData.transactionID,
                paymentMethod: 'Pre-Paid Code',
                seller: producerId,
                sellerEarnings: sellerEarnings,
                value: cost,
            };

            await setDoc(earningsRef, earningsData);

            const producerRef = doc(db, 'users', producerId);
            const producerDoc = await getDoc(producerRef);

            if (producerDoc.exists()) {
                const producerData = producerDoc.data();
                const currentTotalRevenue = parseFloat(producerData.earningsTotalRevenue) || 0;
                const currentEarningBalance = parseFloat(producerData.earningBalance) || 0;
                const newTotalRevenue = currentTotalRevenue + parseFloat(sellerEarnings);
                const newEarningBalance = currentEarningBalance + parseFloat(sellerEarnings);

                await updateDoc(producerRef, {
                    earningsTotalRevenue: newTotalRevenue.toFixed(2),
                    earningBalance: newEarningBalance.toFixed(2),
                });
            }
        } catch (error) {
            console.error("Error subscribing: ", error);
            setError("Failed to subscribe. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const lipaNumber = '57886370';
    const phoneNumber = '+255760808652';

    const handleCopyLipaNumber = () => {
        navigator.clipboard.writeText(lipaNumber).then(() => {
            toast.success(`Lipa Number ${lipaNumber} has been copied to the clipboard`);
        }).catch((err) => {
            toast.error('Failed to copy the Lipa Number');
        });
    };

    return (
        <div>
            {isSubscribed && (
                <div className={`flex text-center justify-center items-center p-[2px] border rounded-lg px-2 w-[120px] h-[35px] max-sm:w-[80px] max-sm:text-[12px] bg-zinc-400 shadow-sm font-semibold text-white`}>
                    Subscribed
                </div>
            )}
            {!isSubscribed && (
                userId !== 'null' ? (
                    <button
                        onClick={() => setIsOpen(true)}
                        className={`flex text-center justify-center items-center p-[2px] border rounded-lg px-2 w-[120px] h-[35px] max-sm:w-[80px] max-sm:text-[12px] cursor-pointer ${isSubscribed ? 'bg-zinc-400' : 'bg-teal-600'} shadow-sm font-semibold text-white`}
                    >
                        Subscribe
                    </button>
                ) : (
                    <button
                        onClick={handleAuth}
                        className={`flex text-center justify-center items-center p-[2px] border rounded-lg px-2 w-[120px] h-[35px] max-sm:w-[80px] max-sm:text-[12px] cursor-pointer ${isSubscribed ? 'bg-zinc-400' : 'bg-teal-600'} shadow-sm font-semibold text-white`}
                    >
                        Subscribe
                    </button>
                )
            )}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50">
                <div className="fixed inset-0 bg-black/30 flex w-screen items-center justify-center p-4 overflow-y-auto" aria-hidden="true">
                    <DialogPanel className="flex flex-col items-center min-w-[512px] max-sm:min-w-[312px] space-y-4 border bg-white/30 backdrop-blur-md p-12 rounded-2xl">
                        <DialogTitle className="font-bold text-[20px] text-center text-blue-500">Subscribe</DialogTitle>
                        <div>
                            <div className="font-bold text-[18px] text-center">Monthly (30 Days)</div>
                            <div className="font-semibold text-[18px] text-center text-green-500">Tsh {parseFloat(cost || "0").toLocaleString()}</div>
                        </div>
                        <div className="max-w-[280px]">
                            <div className='text-start text-[14px]'>
                                To buy pre-paid code voucher send money (Tsh {parseFloat(cost || "0").toLocaleString()}) to this Mpesa Lipa Number
                                <span className='font-bold cursor-pointer hover:text-blue-500' onClick={handleCopyLipaNumber}>
                                    {' '}{lipaNumber}
                                </span>
                                {' '}iTrood Distributers or call to speak to our customer care
                                <a href={`tel:${phoneNumber}`} className='font-bold hover:text-blue-500'>
                                    {' '}{phoneNumber}
                                </a>
                                <ToastContainer />
                            </div>
                        </div>
                        <div className="flex flex-col w-full justify-center items-center text-center">
                            {error && <div className="text-red-500">{error}</div>}
                            {success && <div className="text-green-500">{success}</div>}
                        </div>
                        {isSubscribed ? (
                            <div className={`mt-4 flex text-center justify-center items-center p-[2px] border rounded-lg px-4 h-[35px] cursor-pointer ${isSubscribed ? 'bg-zinc-400' : 'bg-blue-500'} shadow-sm font-semibold text-white`}>
                                Subscribed
                            </div>
                        ) : (
                            <div className="mt-4 flex flex-col justify-center items-center">
                                <input
                                    type="text"
                                    maxLength="15"
                                    required
                                    placeholder="Enter prepaid code"
                                    value={prePaidCode}
                                    onChange={(e) => setPrepaidCode(e.target.value)}
                                    className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                />
                                <button
                                    onClick={handleSubscribe}
                                    disabled={loading}
                                    className={`mt-4 flex text-center justify-center items-center p-[2px] border rounded-lg px-4 h-[35px] cursor-pointer ${isSubscribed ? 'bg-zinc-400' : 'bg-blue-500'} shadow-sm font-semibold text-white`}
                                >
                                    {loading ? 'Subscribing...' : isSubscribed ? 'Subscribed' : 'Subscribe'}
                                </button>
                            </div>
                        )}
                    </DialogPanel>
                </div>
            </Dialog>
        </div>
    );
};

export default Subscribe;