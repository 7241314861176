import React, { useContext, useState, useEffect } from 'react';
import { DarkModeContext } from '../context/darkModeContext';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { logo2 } from '../assets';

const Slider = () => {
  const { darkMode } = useContext(DarkModeContext);
  const [movies, setMovies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const moviesCollection = collection(db, 'movies');
        const q = query(moviesCollection, orderBy('timeStamp', 'desc'), limit(10));
        const querySnapshot = await getDocs(q);
        const fetchedMovies = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data?.BannerImage) { 
            fetchedMovies.push(data);
          }
        });
        setMovies(fetchedMovies);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % movies.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [movies]);

  if (loading) {
    return (
      <div className="relative h-[300px] max-sm:h-[220px] 2xl:h-[400px] w-full flex-shrink-0 animate-pulse bg-gray-100 dark:bg-gray-700 dark:text-white overflow-hidden">
        <img alt="iTrood" src={logo2} className="shrink-0 resize-none object-cover overflow-hidden w-full" />
      </div>
    );
  }

  if (movies.length === 0) {
    return (
      <div className="relative h-[300px] max-sm:h-[220px] 2xl:h-[400px] w-full flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:text-white overflow-hidden">
        <img alt="iTrood" src="https://bgr.com/wp-content/uploads/2020/08/disney-plus.jpg?quality=82&strip=all&resize=1400,1050" className="shrink-0 resize-none object-cover overflow-hidden w-full h-full" />
      </div>
    );
  }

  return (
    <div className="relative h-[300px] max-sm:h-[220px] 2xl:h-[400px] w-full flex-shrink-0 bg-gray-100 dark:bg-gray-700 dark:text-white overflow-hidden">
      {movies.map((item, index) => (
        <img
          key={index}
          alt={item.title}
          src={item.BannerImage}
          className={`absolute top-0 left-0 w-full object-contain transition-opacity duration-1000 ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
        />
      ))}
    </div>
  );
};

export default Slider;