import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getDownloadURL, ref, getMetadata, StorageError } from 'firebase/storage';
import { doc, getDoc, deleteDoc, setDoc, updateDoc, serverTimestamp, query, getDocs, where, collection, getFirestore, Timestamp } from 'firebase/firestore';
import RotateLoader from 'react-spinners/esm/RotateLoader';
import { db, auth, storage } from "../firebase";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPlayer from 'react-player';
import { DarkModeContext } from '../context/darkModeContext';
import { downloadIcon } from '../assets';
import { MdVerified } from "react-icons/md";
import { MdOutlineVerified } from "react-icons/md";
import axios from 'axios';
import { useUserData } from '../lib/userData';
import { AnimatePresence, motion } from "framer-motion";
import Subscribe from './Subscribe';

const Film = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : 'null';
  const userData = useUserData();
  const { darkMode } = useContext(DarkModeContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [movie, setMovie] = useState(null);
  const [producerImage, setProducerImage] = useState('');
  const [producerVerificationStatus, setProducerVerificationStatus] = useState(null);
  const [access, setAccess] = useState(null);
  const [progress, setProgress] = useState(null);
  const [prepaidCode, setPrepaidCode] = useState("");
  const [purchasing, setPurchasing] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [timeSpent, setTimeSpent] = useState(0);
  const [downloadTime, setDownloadTime] = useState(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeSpent(prevTime => prevTime + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timeSpent === 30) {
      console.log('Might be Interested');
    } else if (timeSpent === 60) {
      console.log('Interested');
      updateTroodsCollection();
    } else if (timeSpent === 120) {
      console.log('Definitely Interested');
      updateGenreInterests();
    }
    // console.log(`Time spent on this page: ${timeSpent} seconds`);
  }, [timeSpent]);

  const updateGenreInterests = async () => {
    console.log('Updating genre interests');

    try {
      // Retrieve existing interests from local storage
      const storedInterests = localStorage.getItem('Interests');
      let interests = storedInterests ? JSON.parse(storedInterests) : [];
      console.log('Current interests:', interests);

      // Determine new genres to add
      const newGenres = movie.Genres.filter(genre => !interests.includes(genre));
      if (newGenres.length > 0) {
        interests = [...interests, ...newGenres];
        localStorage.setItem('Interests', JSON.stringify(interests));
        console.log('Updated local genre interests:', interests);
      }

      // Update Firestore
      const userRef = doc(db, `users/${userId}`);
      const userDoc = await getDoc(userRef);

      let firestoreInterests = [];
      if (userDoc.exists()) {
        firestoreInterests = userDoc.data().Interests || [];
        // console.log('Current Firestore interests:', firestoreInterests);
      }

      const newFirestoreGenres = movie.Genres.filter(genre => !firestoreInterests.includes(genre));
      if (newFirestoreGenres.length > 0) {
        firestoreInterests = [...firestoreInterests, ...newFirestoreGenres];
        await setDoc(userRef, { Interests: firestoreInterests }, { merge: true });
        // console.log('Updated Firestore genre interests:', firestoreInterests);
      }
    } catch (error) {
      console.error('Error updating genre interests:', error);
    }
  };

  const updateTroodsCollection = async () => {
    // Check if the user is a premium subscriber
    if (localStorage.getItem('premiumSubscription') !== 'subscriber') {
      console.log('User is not a premium subscriber. Skipping update.');
      return;
    }

    try {
      // Fetch the user's subscription details from Firestore
      const subscriptionRef = doc(db, 'premiumSubscriptions', userId);
      const subscriptionDoc = await getDoc(subscriptionRef);

      if (subscriptionDoc.exists()) {
        const subscriptionData = subscriptionDoc.data();

        // Check if the user is on a 'Free Trial'
        if (subscriptionData.plan === 'Free Trial') {
          console.log('User is on a Free Trial. Skipping update.');
          return;
        }
      } else {
        console.log('Subscription details not found. Skipping update.');
        return;
      }

      console.log('Updating troods collection');
      const troodRef = doc(db, 'troods', movie.ProducerID);
      const troodDoc = await getDoc(troodRef);
      const currentTimestamp = Timestamp.now();
      const expireTime = Timestamp.fromDate(new Date(currentTimestamp.toDate().getTime() + 30 * 24 * 60 * 60 * 1000));

      let users = [];
      if (troodDoc.exists()) {
        users = troodDoc.data().users || [];
        // console.log('Current users in troods:', users);
      }

      if (!users.some(user => user.id === userId)) {
        users.push({ id: userId, timestamp: currentTimestamp, expireTime });
        await setDoc(troodRef, { users }, { merge: true });
        console.log('Updated troods collection:', users);
      }
    } catch (error) {
      console.error('Error updating troods collection:', error);
    }
  };

  const handleDownloadClick = (downloadLink) => {
    console.log('Download button clicked');
    const newTab = window.open(downloadLink, '_blank');
    const startDownloadTime = Date.now();

    const checkNewTab = setInterval(() => {
      if (newTab && newTab.closed) {
        clearInterval(checkNewTab);
        const endDownloadTime = Date.now();
        const timeSpentDownloading = (endDownloadTime - startDownloadTime) / 1000;
        setDownloadTime(timeSpentDownloading);
        console.log(`Time spent on the download page: ${timeSpentDownloading} seconds`);

        if (timeSpentDownloading >= 60 && timeSpentDownloading < 120) {
          console.log('User might have decided to download the movie');
        } else if (timeSpentDownloading >= 120) {
          console.log('User might have been watching the movie');
        }
      }
    }, 1000);
  };

  useEffect(() => {
    const fetchMovie = async () => {
      try {
        const movieDocRef = doc(db, 'movies', id);
        const movieDocSnapshot = await getDoc(movieDocRef);
        if (movieDocSnapshot.exists()) {
          // If the movie document exists, set the movie state
          setMovie(movieDocSnapshot.data());
          // console.log(movieDocSnapshot.data());
        } else {
          console.error('Movie not found.');
        }
      } catch (error) {
        console.error('Error fetching movie:', error);
      }
    };

    fetchMovie();
  }, [id]);

  // Fetch the producer document when the movie data is available
  useEffect(() => {
    const fetchProducer = async () => {
      if (movie && movie.ProducerID) {
        try {
          const producerDocRef = doc(db, 'users', movie.ProducerID);
          const producerDocSnap = await getDoc(producerDocRef);
          if (producerDocSnap.exists()) {
            const producerData = producerDocSnap.data();
            setProducerImage(producerData.img);
            setProducerVerificationStatus(producerData.verification);
          } else {
            console.error('Producer not found.');
          }
        } catch (error) {
          console.error('Error fetching producer:', error);
        }
      }
    };

    fetchProducer();
  }, [movie]);

  const handleDelete = async () => {
    if (movie && movie.ProducerID === userId) {
      // Show confirmation alert
      confirmAlert({
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this movie?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              try {
                const movieDocRef = doc(db, "movies", id);
                const movieDocSnapshot = await getDoc(movieDocRef);

                if (movieDocSnapshot.exists()) {
                  // Move the movie document to the Recycle Bin collection
                  await setDoc(doc(db, "recycleBin", id), movieDocSnapshot.data());

                  // Delete the movie document from the movies collection
                  await deleteDoc(movieDocRef);

                  // Redirect user to the '/movies' page
                  navigate('/movies');

                  // Show success message
                  toast.success("Movie moved to Recycle Bin successfully!");
                } else {
                  toast.error("Movie not found.");
                }
              } catch (err) {
                console.log(err);
                toast.error("Failed to move movie to Recycle Bin.");
              }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      toast.error("You are not authorized to delete this movie.");
    }
  };

  const handleEdit = () => {
    if (movie && movie.ProducerID === userId) {
      // Navigate to the edit page with the movie ID
      navigate(`/movies/film/edit/${id}`);
    } else {
      toast.error("You are not authorized to edit this movie.");
    }
  };

  const download = async () => {
    try {
      // Get the download URL of the movie from Firebase Firestore
      const downloadURL = movie.download;

      // Start the download using Axios
      axios({
        url: downloadURL,
        method: 'GET',
        responseType: 'blob', // Set the response type to blob
      })
        .then((res) => {
          // Create a URL for the Blob object
          const url = window.URL.createObjectURL(new Blob([res.data]));

          // Create a new anchor element to trigger the download
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${movie.MovieTitle}.mp4`); // Set the filename for download
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.error('Error downloading movie:', err);
          toast.error('Failed to download movie.');
        });
    } catch (error) {
      console.error('Error downloading movie:', error);
      toast.error('Failed to download movie.');
    }
  };

  const fastDownload = () => {
    axios({
      url: movie.DownloadLink,
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', movie.MovieTitle);
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        alert(err);
      })
  };

  const startDownload = (link, filename) => {
    const anchor = document.createElement('a');
    anchor.href = link;
    anchor.setAttribute('download', filename);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    const checkAccess = async () => {
      setLoading(true);

      let currentTimestamp;

      try {
        const serverTimeDoc = await getDoc(doc(getFirestore(), 'serverTime', 'serverTimeDoc'));
        currentTimestamp = serverTimeDoc.exists() ? serverTimeDoc.data().timestamp : Timestamp.now();
      } catch (error) {
        console.error('Error fetching server timestamp:', error);
        currentTimestamp = Timestamp.now();
      }

      try {
        // Check if the user has a valid premium subscription
        const premiumSubscriptionRef = doc(db, 'premiumSubscriptions', userId);
        const premiumDocSnapshot = await getDoc(premiumSubscriptionRef);

        if (premiumDocSnapshot.exists()) {
          const premiumData = premiumDocSnapshot.data();
          if (currentTimestamp <= premiumData.expireDate) {
            setAccess(true);
            setLoading(false);
            console.log('User has a valid premium subscription');
            return;
          }
        }
      } catch (error) {
        console.error('Error checking premium subscription:', error);
      }

      try {
        // Check if the user has bought the movie
        const userLibraryRef = doc(db, `users/${userId}/library/${id}`);
        const docSnapshot = await getDoc(userLibraryRef);
        if (docSnapshot.exists()) {
          setAccess(true);
          setLoading(false);
          console.log('User has bought this movie');
          return;
        }

        // Check if the user is subscribed to the producer
        const userSubscriptionRef = collection(db, 'subscriptions');
        const q = query(userSubscriptionRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);

        let accessGranted = false;

        for (const doc of querySnapshot.docs) {
          const data = doc.data();
          if (data.producerId === movie.ProducerID) {
            if (currentTimestamp > data.expireDate) {
              if (!data.expire) {
                await updateDoc(doc.ref, { expire: true });
              }
            } else {
              accessGranted = true;
              break;
            }
          }
        }

        setAccess(accessGranted);
        setLoading(false);
        console.log(accessGranted ? 'User has subscribed' : 'User has not subscribed to this producer');
      } catch (error) {
        console.error('Error checking access:', error);
        setLoading(false);
      }
    };

    if (userId && id && movie) {
      checkAccess();
    }
  }, [userId, id, movie]);

  const handleAuth = () => {
    // Store the current location before redirecting to login
    const currentLocation = `/movies/film/${id}`;
    localStorage.setItem('prevLocation', currentLocation);

    // Redirect to the login page
    navigate('/login');
  };

  const handleBuyClick = () => {
    setIsPaymentModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsPaymentModalOpen(false);
  };

  const nowPurchase = async (docData, selectedMethod, currency, value) => {
    setPurchasing(true);
    try {
      const userLibraryRef = doc(db, `users/${userId}/library/${id}`);
      const movieData = {
        id: id,
        name: movie.MovieTitle,
        image: movie.CoverImage,
        type: movie.Type,
        purchaseDate: serverTimestamp(),
        transactionID: docData.transactionID,
        paymentGateway: selectedMethod,
        currency: currency,
        amount: value,
        library: userId,
        userName: userData.displayName,
      };
      // console.log(movieData);

      await setDoc(userLibraryRef, movieData);

      // Calculate earnings
      const iTroodEarnings = (value * 0.5).toFixed(2);
      const sellerEarnings = (value * 0.5).toFixed(2);

      // add purchase document in "earnings" collection
      const earningsRef = doc(db, 'earnings', docData.transactionID);
      const earningsData = {
        buyer: userId,
        currency: currency,
        iTroodEarnings: iTroodEarnings,
        id: docData.transactionID,
        paymentMethod: selectedMethod,
        seller: movie.ProducerID,
        sellerEarnings: sellerEarnings,
        value: value,
      };
      // console.log(earningsData);

      await setDoc(earningsRef, earningsData);

      // Update seller's earnings
      const sellerRef = doc(db, 'users', movie.ProducerID);
      const sellerDoc = await getDoc(sellerRef);

      if (sellerDoc.exists()) {
        const sellerData = sellerDoc.data();
        const currentTotalRevenue = parseFloat(sellerData.earningsTotalRevenue) || 0;
        const currentEarningBalance = parseFloat(sellerData.earningBalance) || 0;
        const newTotalRevenue = currentTotalRevenue + parseFloat(sellerEarnings);
        const newEarningBalance = currentEarningBalance + parseFloat(sellerEarnings);

        await updateDoc(sellerRef, {
          earningsTotalRevenue: newTotalRevenue.toFixed(2),
          earningBalance: newEarningBalance.toFixed(2),
        });
      }

      // Update state to reflect access to the movie
      setAccess(true);

      // Show success message
      toast.success('Movie purchased successfully and added to your library!');
    } catch (error) {
      setPurchasing(false);
      console.error('Error purchasing movie:', error);
      toast.error('Failed to purchase movie. Please try again.');
    }
    setPurchasing(false);
  };

  const subscriptionCost = '3000';

  return (
    <div className='max-sm:pb-[100px]'>
      {movie ? (
        <div>
          <div>
            <div className={`rounded-2xl shadow-xl h-[200px] max-md:h-fit flex max-md:flex-col justify-between p-5 px-10 max-md:px-4 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-white"}`}>
              <div className='flex justify-between max-md:justify-start gap-5 flex-shrink-0'>
                <img
                  src={movie.CoverImage}
                  alt={`${movie.MovieTitle} | iTrood`}
                  title={`${movie.MovieTitle} | iTrood`}
                  className='bg-zinc-400 w-[120px] rounded-2xl'
                />
                <div className=''>
                  <div className='font-bold text-[18px] pb-1'>{movie.MovieTitle}{" "}{movie?.status && <span className='text-[8px] text-yellow-400'>{movie?.status}</span>}</div>
                  <div className='flex gap-1'>
                    <div className='text-[14px] text-slate-500 font-medium'>{movie.movieCountry}</div>
                    <div className='text-[14px] text-slate-600 font-medium'>{movie.Type}</div>
                  </div>
                  {movie.Genres.length > 0 ? (
                    <>
                      {movie.Genres.map((genre, index) => (
                        <div key={index} className='text-[14px]'>{genre}</div>
                      ))}
                    </>
                  ) : (
                    <div className='text-[14px]'>Genre</div>
                  )}
                  <div className='text-[14px]'>Duration: {movie.Duration}</div>
                </div>
              </div>
              <div className='flex max-md:flex-col justify-between gap-8'>
                {movie.Trailer && (
                  <div className='max-md:mt-1'>
                    <ReactPlayer
                      light={true}
                      controls={true}
                      url={movie?.Trailer}
                      height="140px"
                      width="260px"
                      className='border-2'
                    />
                    <div className='text-[12px] text-slate-400 font-semibold text-center'>Trailer</div>
                  </div>
                )}
                <div className='w-[2px] bg-gray-200 h-full max-md:hidden' />
                <Link to={`/producer/${movie.ProducerID}`} className='items-center flex flex-col max-md:flex-row flex-shrink-0'>
                  <img
                    src={producerImage || 'https://firebasestorage.googleapis.com/v0/b/trood-app-c5eae.appspot.com/o/1712773008995iTrood.png?alt=media&token=34f6279d-1425-4016-a57b-83a12115c27d'}
                    alt={`${movie.ProducerName} iTrood`}
                    title={movie.ProducerName}
                    className='h-[80px] w-[80px] m-2 shrink-0 object-cover rounded-full bg-zinc-100'
                  />
                  <div className='pt-2'>
                    <div className='gap-1 flex justify-center items-center'>
                      <div className='text-[13px] font-semibold text-center'>{movie.ProducerName || "DJ XXX"}</div>
                      {producerVerificationStatus === "verified" && (
                        <div className='text-[13px] font-semibold text-center'>{darkMode ? <MdOutlineVerified /> : <MdVerified color='blue' />}</div>
                      )}
                    </div>
                    <div className='text-[12px] font-semibold text-center text-gray-300'>Producer</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='m-2 flex flex-col items-center justify-center'>
            <h2 className='ml-2 font-bold text-slate-400'>Download</h2>
            <p>{movie?.Description}</p>
            <div className='m-2'>
              {access && !loading ? (
                <div className='flex flex-col justify-between items-center'>
                  {movie && (
                    <>
                      {movie?.Type === 'Single Film' && movie?.Parts?.length > 0 ? (
                        movie.Parts.map((part, index) => (
                          <div
                            key={index}
                            className={`m-2 flex p-2 justify-between items-center w-[315px] max-sm:w-fit gap-4 h-[80px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin resize-none shadow-md ${darkMode
                              ? 'border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]'
                              : 'light-mode'
                              }`}
                          >
                            <div className="flex gap-1 items-center">
                              <img
                                alt={`${movie.MovieTitle} Part ${index + 1} | iTrood`}
                                src={movie.CoverImage}
                                className="h-[50px] w-[35px] m-2 shrink-0 rounded-md bg-zinc-200"
                              />
                              <div>
                                <p className="text-[14px] font-semibold">{movie.MovieTitle}</p>
                                <p className="text-[12px]">{part.title}</p>
                              </div>
                            </div>
                            <a
                              href={part.downloadLink}
                              onClick={() => handleDownloadClick(part.downloadLink)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex flex-col gap-1"
                            >
                              <div className="h-[40px] w-[40px] justify-center items-center flex bg-zinc-100 hover:bg-zinc-200 rounded-full border">
                                <img alt="iTrood" src={downloadIcon} className="h-[20px] object-contain" />
                              </div>
                              <p className="text-[10px] font-semibold">Download</p>
                            </a>
                          </div>
                        ))
                      ) : (
                        <div
                          className={`m-2 flex p-2 justify-between items-center w-[315px] max-sm:w-fit gap-4 h-[80px] min-h-[45px] max-h-[100px] rounded-xl scrollbar-thin resize-none shadow-md ${darkMode
                            ? 'border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]'
                            : 'light-mode'
                            }`}
                        >
                          <div className="flex gap-1 items-center">
                            <img
                              alt={`${movie.MovieTitle} | iTrood`}
                              src={movie.CoverImage}
                              className="h-[50px] w-[35px] m-2 shrink-0 rounded-md bg-zinc-200"
                            />
                            <div>
                              <p className="text-[14px] font-semibold">{movie.MovieTitle}</p>
                              <p className="text-[12px]">{movie.Duration}</p>
                            </div>
                          </div>
                          <a
                            href={movie.DownloadLink}
                            onClick={() => handleDownloadClick(movie.DownloadLink)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex flex-col gap-1"
                          >
                            <div className="h-[40px] w-[40px] justify-center items-center flex bg-zinc-100 hover:bg-zinc-200 rounded-full border">
                              <img alt="iTrood" src={downloadIcon} className="h-[20px] object-contain" />
                            </div>
                            <p className="text-[10px] font-semibold">Download</p>
                          </a>
                        </div>
                      )}

                      {movie?.video_url && (
                        <div className="max-md:mt-1 max-sm:w-[80%] flex flex-col w-full">
                          <ReactPlayer light={false} controls={true} url={movie?.video_url} autoPlay />
                          <div className="text-[12px] text-slate-400 font-semibold text-center">Watch</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {isPaymentModalOpen ? (
                    <PaymentModal isOpen={isPaymentModalOpen} onClose={handleCloseModal} nowPurchase={nowPurchase} userId={userId} producerId={movie.ProducerID} filmId={movie.id} darkMode={darkMode} />
                  ) : (
                    userId !== 'null' ? (
                      loading ? (
                        <div><RotateLoader color="#3693d6" /></div>
                      ) : (
                        <div className='flex flex-col gap-2 justify-center items-center'>
                          <button onClick={handleBuyClick} className='text-center text-blue-500 cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>BUY</button>
                          <Subscribe cost={subscriptionCost} producerId={movie.ProducerID} />
                          <div className='flex w-full h-[1px] bg-slate-400' />
                          <div className='text-slate-400'>Tafathali jua kwamba unapobonyeza kitufe cha BUY inamaanisha kwamba unanunua hii tu, Pia unapobonyeza kitufe cha Subscribe inamaana kwamba umechagua unalipia kwa kila mwezi na kuweza kupata movie zote za {movie.ProducerName}.</div>
                          <div className='text-slate-400'>Please note that when you click BUY it means you're buying an individual movie or film and when you click at Subscribe button that means you'll be paying a monthly subscription and watch all the movies from {movie.ProducerName}.</div>
                        </div>
                      )
                    ) : (
                      <button onClick={handleAuth} className='text-center text-blue-500 cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>Login to BUY & Watch</button>
                    )
                  )}
                </>
              )}
              {progress !== null && (
                <progress value={progress} max="100" className="w-full mt-4"></progress>
              )}
              {/* {progress ? (
                <div className='text-[14px] text-center p-2 m-2 text-blue-500 font-semibold'>Downloading {progress}%</div>
              ) : (
                <div onClick={() => startDownload(movie.DownloadLink, `${movie.MovieTitle}.mp4`)} className='text-[14px] text-center p-2 m-2 text-blue-500 font-semibold cursor-pointer'>Fast Download</div>
              )} */}
            </div>
          </div>
          {movie && movie.ProducerID === userId && (
            <div className='flex w-full h-[100px] gap-4 justify-center items-center'>
              <button onClick={handleEdit} className='text-center text-blue-500 cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>Edit</button>
              <button onClick={handleDelete} className='text-center text-red-500 cursor-pointer border-spacing-4 rounded-xl border-red-500 hover:bg-red-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>Delete</button>
            </div>
          )}
        </div>
      ) : (
        // Loading skeleton
        <div>
          Loading...
        </div>
      )}
    </div>
  );
};

const PaymentModal = ({ isOpen, onClose, nowPurchase, userId, producerId, filmId, darkMode }) => {
  const paymentOptions = [
    // {
    //   id: 1,
    //   name: 'Credit Card',
    //   desc: 'Pay with your card using Stripe'
    // },
    // {
    //   id: 2,
    //   name: 'PayPal',
    //   desc: 'Pay using PayPal'
    // },
    // {
    //   id: 3,
    //   name: 'Mobile Payment',
    //   desc: 'Use mobile payment with AzamPay'
    // },
    {
      id: 4,
      name: 'Pre-Paid Code',
      desc: 'Use Voutcher, Gift Cards and Promo Codes to pay'
    }
  ];

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [purchasing, setPurchasing] = useState(false);
  const [codeData, setCodeData] = useState(null);

  const handlePaymentSelection = (method) => {
    setSelectedMethod(method);
  };

  const handlePurchase = async () => {
    setPurchasing(true);

    if (!selectedMethod) {
      // If no payment method is selected, handle accordingly (e.g., show error message)
      toast.error('No payment method selected');
      setPurchasing(false);
      return;
    }

    if (selectedMethod === 'Pre-Paid Code') {
      const codeInput = document.querySelector('input[type="text"]').value;

      if (!codeInput) {
        toast.error('No code entered');
        setPurchasing(false);
        return;
      }

      try {
        // Retrieve the document from the 'prePaidCodes' collection based on the entered code
        const docRef = doc(db, 'prePaidCodes', codeInput);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const docData = docSnap.data();
          setCodeData(docData);
          const { id, code, content, creditedTo, expire, expireDate, value, currency, used, user } = docData;

          // Check if the entered code matches and is not expired
          if (code === codeInput) {
            if (expire !== 'yes') {
              if (value === '500') {
                if (currency === 'TZS') {
                  // Update the code as used
                  await updateDoc(docRef, {
                    expire: 'yes',
                    expireDate: serverTimestamp(),
                    used: 'true',
                    user: userId,
                    content: filmId,
                    creditedTo: [{ 'producer': producerId, earned: '50%' }, { 'company': 'iTrood, Inc', earned: '50%' }],
                  });

                  // Proceed with the purchase logic (e.g., unlock content)
                  nowPurchase(docData, selectedMethod, currency, value);
                  console.log('Purchasing!...');

                  // Reset the selected method after successful purchase
                  setSelectedMethod(null);
                } else {
                  toast.error("Currency doesn't match, code can't purchase");
                }
              } else {
                toast.error("Insufficient balance, code can't purchase");
              }
            } else {
              toast.error('Expired code, It has been used');
            }
          } else {
            toast.error('Code mismatch');
          }
        } else {
          // Code not found in the database
          toast.error('Invalid or code not found');
        }
      } catch (error) {
        toast.error(`Error processing payment: ${error.message}`);
      } finally {
        setPurchasing(false);
      }
    } else {
      // Logic for other payment methods (e.g., Stripe, PayPal) can be added here
      console.log(`Purchasing with ${selectedMethod}`);
      // Implement corresponding purchase logic for other methods
      setPurchasing(false);
    }
  };

  const price = '500';
  const lipaNumber = '57886370';
  const phoneNumber = '+255760808652';

  const handleCopyLipaNumber = () => {
    navigator.clipboard.writeText(lipaNumber).then(() => {
      toast.success(`Lipa Number ${lipaNumber} has been copied to the clipboard`);
    }).catch((err) => {
      toast.error('Failed to copy the Lipa Number');
    });
  };

  const handleCall = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.2 }}
          className={`flex flex-col rounded-xl p-[10px] gap-2 h-fit w-[300px] max-md:w-fit border shadow-xl mb-20 ${darkMode ? "border shadow-[0px_0px_4px_2px_rgba(200,_300,_400,_0.50)] hover:shadow-[0px_0px_4px_2px_rgba(0,_179,_255,_0.23)]" : "light-mode bg-indigo-100"}`}
        >
          <h2 className='text-green-400 text-center'>Select Payment Method</h2>
          <ul>
            {paymentOptions.map((option, index) => (
              <motion.li
                key={index}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
                onClick={() => handlePaymentSelection(option.name)}
                className={`cursor-pointer flex flex-col p-1 justify-center rounded-lg border h-[50px] my-2 w-full ${selectedMethod === option.name ? 'border-blue-500 shadow-md' : darkMode ? 'bg-[#302c3878]' : 'bg-white'}`}
              >
                <div>{option.name}</div>
                <div className='text-xs max-sm:text-[10px]'>{option.desc}</div>
              </motion.li>
            ))}
          </ul>

          {/* Render selected method section */}
          {selectedMethod && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.2 }}
              className="flex flex-col p-2 m-2 gap-2 justify-center items-center"
            >
              <div className='text-start text-[14px]'>
                To buy pre-paid code voucher send money (Tsh {price}) to this Mpesa Lipa Number
                <span className='font-bold cursor-pointer hover:text-blue-500' onClick={handleCopyLipaNumber}>
                  {' '}{lipaNumber}
                </span>
                {' '}iTrood Distributers or call to speak to our customer care
                <a href={`tel:${phoneNumber}`} className='font-bold hover:text-blue-500'>
                  {' '}{phoneNumber}
                </a>
                <ToastContainer />
              </div>
              <p className='text-center text-green-400'>Now you can pay with {selectedMethod}</p>
              {selectedMethod === 'Pre-Paid Code' && (
                <div className='flex flex-col gap-2 justify-center items-center w-full'>
                  <input type="text" placeholder="Enter code" maxLength="15" required className='rounded-lg bg-transparent border-blue-500' />
                  <button onClick={handlePurchase} className='text-center text-blue-500 cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>{purchasing ? 'Purchasing...' : 'Purchase'}</button>
                </div>
              )}
              {selectedMethod !== 'Pre-Paid Code' && (
                <button onClick={handlePurchase} className='text-center text-blue-500 cursor-pointer border-spacing-4 rounded-xl border-blue-500 hover:bg-blue-500 hover:text-white hover:font-bold border-2 p-2 w-fit'>{purchasing ? 'Purchasing...' : 'Purchase'}</button>
              )}
            </motion.div>
          )}
          <button onClick={onClose} className='text-red-500'>Close</button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Film;