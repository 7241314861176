import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { icon, logo } from '../assets';
import { DarkModeContext } from '../context/darkModeContext';
import { MdSpaceDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { MdLocalMovies, MdVideoLibrary } from "react-icons/md";
import { BiLogInCircle, BiLogOutCircle } from "react-icons/bi";
import { FaRegSquarePlus } from "react-icons/fa6";
import { MdOutlineMovieFilter } from "react-icons/md";
import { BsFilePost } from "react-icons/bs";
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';

const MobileBottomNavigationBar = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : 'null';
  const { darkMode } = useContext(DarkModeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(getActiveLink(location.pathname));

  function getActiveLink(pathname) {
    const activeLink = navLinks.find(nav => `/${nav.id}` === pathname);
    return activeLink ? activeLink.title : "Dashboard";
  }

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      // Remove user from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("user");
      // Redirect to the login page after logout
      navigate("/login");
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  return (
    <div className={`bottom-0 w-flex justify-center items-center px-5 py-2`}>
      <div className='flex w-full justify-center items-center gap-8'>
        {navLinks.map((nav, index) => (
          <CustomLink to={`/${nav.id}`} key={nav.id} onClick={() => setActive(nav.title)}>
            <ul className={`flex gap-1 items-center py-2 m-1 p-4 max-sm:p-1 cursor-pointer ${darkMode ? "hover:bg-[#49425778] text-white" : "light-mode hover:bg-zinc-100 "}`}>
              {React.cloneElement(nav.icon, {
                className: `${active === nav.title ? darkMode ? "text-white" : "light-mode text-black" : "text-slate-500"} ${nav.icon.props.className}`
              })}
            </ul>
          </CustomLink>
        ))}
      </div>
    </div>
  )
}

export const navLinks = [
  {
    id: "",
    title: "Home",
    icon: <MdLocalMovies />
  },
  {
    id: "store",
    title: "My Movies",
    icon: <MdVideoLibrary />
  },
  {
    id: "producers",
    title: "DJs | Producers",
    icon: <FaUsers />
  },
];

function CustomLink({ to, children, ...props }) {
  return (
    <li>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

export default MobileBottomNavigationBar;