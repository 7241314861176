import React, { useContext, useState, useEffect } from 'react';
import Carousel from "../code/Carousel";
import { logo2 } from '../assets';
import { DarkModeContext } from '../context/darkModeContext';
import { collection, query, orderBy, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import Slider from '../code/Slider';

const Movies = () => {
  const { darkMode } = useContext(DarkModeContext);
  const [movies, setMovies] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [singleFilms, setSingleFilms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('Latest');

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const moviesCollection = collection(db, 'movies');
        const q = query(moviesCollection, orderBy('timeStamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const fetchedMovies = [];
        querySnapshot.forEach((doc) => {
          fetchedMovies.push(doc.data());
        });
        setMovies(fetchedMovies);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, []);

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const moviesCollection = collection(db, 'movies');
        const q = query(moviesCollection, where('Type', '==', 'Season'), orderBy('timeStamp', 'desc')); // Sort by upload date in descending order
        const querySnapshot = await getDocs(q);
        const fetchedSeasons = [];
        querySnapshot.forEach((doc) => {
          fetchedSeasons.push(doc.data());
        });
        setSeasons(fetchedSeasons);
      } catch (error) {
        console.error('Error fetching seasons:', error);
      }
    };

    fetchSeasons();
  }, []);

  useEffect(() => {
    const fetchSingleFilms = async () => {
      try {
        const moviesCollection = collection(db, 'movies');
        const q = query(moviesCollection, where('Type', '==', 'Single Film'), orderBy('timeStamp', 'desc')); // Sort by upload date in descending order
        const querySnapshot = await getDocs(q);
        const fetchedSingleFilms = [];
        querySnapshot.forEach((doc) => {
          fetchedSingleFilms.push(doc.data());
        });
        setSingleFilms(fetchedSingleFilms);
      } catch (error) {
        console.error('Error fetching single films:', error);
      }
    };

    fetchSingleFilms();
  }, []);

  // Function to handle filter change
  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  // Filter movies based on the selected filter
  const filteredMovies = () => {
    switch (selectedFilter) {
      case 'Latest':
        return movies;
      case 'This Week':
        // Filter movies uploaded within the current week
        const today = new Date();
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return movies.filter(movie => new Date(movie.timeStamp.toDate()) > oneWeekAgo);
      case 'Single Film':
        return singleFilms;
      case 'Seasons':
        return seasons;
      case 'Trends':
        // Implement your logic to filter trending movies
        // For example: return movies.filter(movie => /* Your trending logic */);
        return movies; // Placeholder
      case 'Popular':
        // Implement your logic to filter popular movies
        // For example: return movies.filter(movie => /* Your popularity logic */);
        return movies; // Placeholder
      case 'Older':
        // Filter movies uploaded before a certain date (e.g., one year ago)
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        return movies.filter(movie => new Date(movie.timeStamp.toDate()) < oneYearAgo);
      default:
        return movies;
    }
  };

  return (
    <div className='max-sm:pb-[100px]'>
      <div>
        <Slider />
      </div>
      <div className='flex justify-between items-center mt-2'>
        <div className='font-bold text-lg'>Movies</div>
        {/* Filter the result dropdown */}
        <div className='text-[14px] text-black blue-500 mb-2'>
          <label htmlFor="filterDropdown" className={`${darkMode ? "text-white" : "light-mode text-slate-500"}`}>Filter:</label>
          <select id="filterDropdown" className="ml-2 p-1 rounded border" value={selectedFilter} onChange={handleFilterChange}>
            <option value="Latest">Latest</option>
            <option value="This Week">This Week</option>
            <option value="Single Film">Single Film</option>
            <option value="Seasons">Seasons</option>
            <option value="Trends">Trends</option>
            <option value="Popular">Popular</option>
            <option value="Older">Older</option>
          </select>
        </div>
      </div>
      {selectedFilter === 'Latest' ? (
        <div>
          <div>
            <div className={`flex flex-row gap-4 overflow-x-auto scrollbar-hide overflow-hidden`}>
              {loading ? (
                <TrendingSkeletonLoader />
              ) : (
                movies.map((item, index) => (
                  <Link
                    to={`/movies/${item.Type === 'Single Film' ? 'film' : 'movie'}/${item.id}`}
                    className={`my-4 cursor-pointer w-[120px] ${index === 0 ? 'pl-15' : ''} ${index === movies.length - 1 ? 'pr-15' : ''}`}
                    key={item.id}
                  >
                    <div className={`h-[180px] w-[120px] shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-200"}`}>
                      <img alt="iTrood" src={item.CoverImage} className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                    </div>
                    <div>
                      <div className='font-semibold line-clamp-1 hover:text-blue-500'>{item.MovieTitle}</div>
                      <div className='text-[12px] text-slate-400 hover:text-green-400'>{item.Type}</div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
          <div>
            <div className='font-bold text-lg'>Seasons</div>
            <div className={`flex flex-row flex-wrap max-sm:grid max-sm:grid-cols-2 gap-4 overflow-x-auto scrollbar-hide`}>
              {loading ? (
                <SkeletonLoader />
              ) : (
                seasons.map((item, index) => (
                  <Link
                    to={`/movies/${item.Type === 'Single Film' ? 'film' : 'movie'}/${item.id}`}
                    className={`my-4 cursor-pointer w-[120px] max-sm:w-full ${index === 0 ? 'pl-15' : ''} ${index === movies.length - 1 ? 'pr-15' : ''}`}
                    key={item.id}
                  >
                    <div className={`h-[180px] max-sm:h-[280px] w-[120px] max-sm:w-full shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-200"}`}>
                      <img alt="iTrood" src={item.CoverImage} className='h-[180px] max-sm:h-[280px] w-[120px] max-sm:w-full hover:max-sm:h-[285px] hover:w-[130px] hover:max-sm:w-full transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                    </div>
                    <div>
                      <div className='font-semibold line-clamp-1 hover:text-blue-500'>{item.MovieTitle}</div>
                      <div className='text-[12px] text-slate-400 hover:text-green-400'>{item.Type}</div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
          <div>
            <div className='font-bold text-lg'>Single Films</div>
            <div className={`flex flex-row flex-wrap gap-4 overflow-x-auto scrollbar-hide`}>
              {loading ? (
                <SkeletonLoader />
              ) : (
                singleFilms.map((item, index) => (
                  <Link
                    to={`/movies/${item.Type === 'Single Film' ? 'film' : 'movie'}/${item.id}`}
                    className={`my-4 cursor-pointer w-[120px] ${index === 0 ? 'pl-15' : ''} ${index === movies.length - 1 ? 'pr-15' : ''}`}
                    key={item.id}
                  >
                    <div className={`h-[180px] w-[120px] shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-200"}`}>
                      <img alt="iTrood" src={item.CoverImage} className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                    </div>
                    <div>
                      <div className='font-semibold line-clamp-1 hover:text-blue-500'>{item.MovieTitle}</div>
                      <div className='text-[12px] text-slate-400 hover:text-green-400'>{item.Type}</div>
                    </div>
                  </Link>
                ))
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={`flex flex-row flex-wrap gap-4 overflow-x-auto scrollbar-hide`}>
            {loading ? (
              <SkeletonLoader />
            ) : (
              filteredMovies().map((item, index) => (
                <Link
                  to={`/movies/${item.Type === 'Single Film' ? 'film' : 'movie'}/${item.id}`}
                  className={`my-4 cursor-pointer w-[120px] ${index === 0 ? 'pl-15' : ''} ${index === movies.length - 1 ? 'pr-15' : ''}`}
                  key={item.id}
                >
                  <div className={`h-[180px] w-[120px] shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-200"}`}>
                    <img alt="iTrood" src={item.CoverImage} className='h-[180px] w-[120px] hover:h-[190px] hover:w-[130px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-xl shrink-0' />
                  </div>
                  <div>
                    <div className='font-semibold line-clamp-1 hover:text-blue-500'>{item.MovieTitle}</div>
                    <div className='text-[12px] text-slate-400 hover:text-green-400'>{item.Type}</div>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const TrendingSkeletonLoader = () => {
  const { darkMode } = useContext(DarkModeContext);

  const skeletons = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]

  return (
    <div className='max-w-full overflow-x-auto scrollbar-hide'>
      <div className='flex flex-row gap-4'>
        {skeletons.map((_, index) => (
          <div key={index} className='my-4 cursor-pointer w-[120px] flex-shrink-0'>
            <div className={`h-[180px] w-[120px] animate-pulse shadow-lg rounded-xl hover:shadow-xl my-4 ${darkMode ? "border" : "light-mode bg-zinc-300 animate-pulse"}`}>
            </div>
            <div>
              <div className='font-semibold line-clamp-1 hover:text-blue-500'></div>
              <div className='text-[12px] text-slate-400 hover:text-green-400'></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
const SkeletonLoader = () => {
  const { darkMode } = useContext(DarkModeContext);

  const skeletons = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]

  return (
    <div className='flex flex-row flex-wrap gap-4 overflow-x-auto scrollbar-hide'>
      {/* Scroll view */}
      {skeletons.map((_, index) => (
        <div key={index} className='my-4 cursor-pointer w-[120px]'>
          <div className={`h-[180px] w-[120px] animate-pulse shadow-lg rounded-xl hover:shadow-xl my-4 flex-shrink-0 ${darkMode ? "border" : "light-mode bg-zinc-300 animate-pulse"}`}>
          </div>
          <div>
            <div className='font-semibold line-clamp-1 hover:text-blue-500'></div>
            <div className='text-[12px] text-slate-400 hover:text-green-400'></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Movies;