import React, { useState, useEffect, useContext, useRef } from 'react';
import { collection, query, orderBy, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { DarkModeContext } from '../context/darkModeContext';
import { RiSearch2Line } from "react-icons/ri";

const Search = () => {
  const { darkMode } = useContext(DarkModeContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [allMovies, setAllMovies] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dbSearchLoading, setDbSearchLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [expandedSearch, setExpandedSearch] = useState(false);
  const searchRef = useRef(null);

  // Fetch all movies once on component mount
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        setLoading(true);
        const moviesCollection = collection(db, 'movies');
        const q = query(moviesCollection, orderBy('timeStamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const fetchedMovies = [];
        querySnapshot.forEach((doc) => {
          fetchedMovies.push({ id: doc.id, ...doc.data() });
        });
        setAllMovies(fetchedMovies);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching movies:', error);
        setLoading(false);
      }
    };

    fetchMovies();
  }, []);

  // Function to handle the search input
  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      const results = allMovies.filter((movie) =>
        movie.MovieTitle.toLowerCase().includes(term.toLowerCase()) // Convert both to lowercase for case-insensitive comparison
      );
      setFilteredResults(results);
      setShowResults(true); // Show results when typing
      setExpandedSearch(false); // Reset expanded search state
    } else {
      setFilteredResults([]);
      setShowResults(false); // Hide results if the input is empty
    }
  };

  // Function to handle clicks outside of the search box
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowResults(false); // Hide results when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to handle when a search result is clicked
  const handleResultClick = (movieTitle) => {
    setSearchTerm(movieTitle); // Set the search term to the clicked movie title
    setShowResults(false); // Hide results
  };

  // Function to expand the search to the database
  const expandSearchToDb = async () => {
    if (searchTerm.trim().length === 0) return;
    
    setDbSearchLoading(true);
    try {
      const moviesCollection = collection(db, 'movies');
      const q = query(moviesCollection, where('MovieTitle', '==', searchTerm));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const dbResults = [];
        querySnapshot.forEach((doc) => {
          dbResults.push({ id: doc.id, ...doc.data() });
        });
        setFilteredResults(dbResults);
      } else {
        setFilteredResults([]); // No results from DB either
      }
    } catch (error) {
      console.error('Error searching movies in DB:', error);
    } finally {
      setDbSearchLoading(false);
      setExpandedSearch(true);
    }
  };

  // Function to handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && filteredResults.length === 0 && searchTerm) {
      expandSearchToDb();
    }
  };

  return (
    <div className={`relative w-[300px] max-sm:hidden`} ref={searchRef}>
      <div className="relative">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
          onClick={() => setShowResults(true)} // Show results when clicking on the input
          onKeyDown={handleKeyDown}
          className={`px-3 py-1 w-full pr-8 ${darkMode ? "bg-[#1b082b] border-b-[#1b082b] text-white" : "bg-[#F9FAFB] border-b-zinc-300"} rounded-md border-hidden`}
        />
        {searchTerm && !expandedSearch && filteredResults.length === 0 && (
          <RiSearch2Line
            className={`absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer ${darkMode ? 'text-white' : 'text-blue-500'}`}
            size={20}
            onClick={expandSearchToDb}
          />
        )}
      </div>
      {showResults && searchTerm && (
        <div
          className={`absolute scrollbar-width z-[999] left-0 right-0 mt-1 ${darkMode ? 'bg-[#1b082b] border-[#1b082b]' : 'bg-white border-zinc-300'} rounded-md shadow-lg max-h-60 overflow-y-auto`}
        >
          {loading ? (
            <div className={`px-3 py-2 ${darkMode ? 'text-zinc-400' : 'text-zinc-500'}`}>
              Loading...
            </div>
          ) : filteredResults.length > 0 ? (
            filteredResults.map((item, index) => (
              <Link
                to={`/movies/${item.Type === 'Single Film' ? 'film' : 'movie'}/${item.id}`}
                key={index}
                className={`flex gap-1 px-2 py-2 h-[90px] w-full ${darkMode ? 'hover:bg-[#2b1b3b]' : 'hover:bg-zinc-100'}`}
                onClick={() => handleResultClick(item.MovieTitle)} // Handle the click and set the movie title
              >
                <img
                  src={item.CoverImage}
                  className='h-[80px] w-[50px] hover:h-[82px] hover:w-[52px] transition-transform duration-300 transform-gpu hover:scale-110 object-cover rounded-md shrink-0'
                />
                <div
                  className={`block px-3 py-2 h-full w-full cursor-pointer ${darkMode ? 'text-white' : 'text-black'} ${darkMode ? 'hover:bg-[#2b1b3b]' : 'hover:bg-zinc-100'}`}
                >
                  {item.MovieTitle}
                </div>
              </Link>
            ))
          ) : !expandedSearch ? (
            <div className={`px-3 py-2 ${darkMode ? 'text-zinc-400' : 'text-zinc-500'}`}>
              Click enter or <button onClick={expandSearchToDb} className="text-blue-500">search for "{searchTerm}"</button>
            </div>
          ) : (
            <div className={`px-3 py-2 ${darkMode ? 'text-zinc-400' : 'text-zinc-500'}`}>
              No results found.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;