import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { icon, logo } from '../assets';
import { DarkModeContext } from '../context/darkModeContext';
import { MdSpaceDashboard } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { MdLocalMovies, MdVideoLibrary } from "react-icons/md";
import { BiLogInCircle, BiLogOutCircle } from "react-icons/bi";
import { FaRegSquarePlus } from "react-icons/fa6";
import { MdOutlineMovieFilter } from "react-icons/md";
import { BsFilePost } from "react-icons/bs";
import { auth } from '../firebase';
import { useModal } from '../context/ModalContext';

const SideBar = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : 'null';
  const { darkMode } = useContext(DarkModeContext);
  const { openModal } = useModal();
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(getActiveLink(location.pathname));
  const [isSubscriber, setIsSubscriber] = useState(localStorage.getItem('premiumSubscription') === 'subscriber');

  useEffect(() => {
    function handleStorageChange() {
      setIsSubscriber(localStorage.getItem('premiumSubscription') === 'subscriber');
    }

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  function getActiveLink(pathname) {
    const activeLink = navLinks.find(nav => `/${nav.id}` === pathname);
    return activeLink ? activeLink.title : "Dashboard";
  }

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      // Remove user from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("user");
      // Retain email, remove other user data from localStorage
      const email = localStorage.getItem("email");
      localStorage.clear();
      if (email) {
        localStorage.setItem("email", email);
      }

      // Redirect to the login page after logout
      navigate("/login");
    }).catch((error) => {
      console.error("Error signing out:", error);
    });
  };

  return (
    <div className={`flex flex-col w-[220px] min-w-[220px] max-md:w-[100px] max-sm:w-[40px] h-[90%] rounded-xl m-4 max-md:m-2 max-sm-m-1 ml-10 max-md:ml-5 max-sm:ml-2 justify-between ${darkMode ? "bg-[#302c3878] border text-white" : "light-mode bg-white"} max-sm:hidden`}>
      <div className='flex flex-col max-sm:justify-center max-sm:items-center'>
        <div className='flex justify-center items-center p-5 max-sm:p-1 max-sm:h-[40px] max-sm:w-[40px] flex-shrink-0'>
          <img alt="iTrood" src={logo} className='h-[100px] w-[100px] max-md:h-[60px] max-md:w-[60px] max-sm:h-[40px] max-sm:w-[40px] shrink-0 object-cover' />
        </div>
        <div className='text-[12px] text-gray-400 font-bold text-center mb-4 max-sm:text-[10px]'>iTrood Shop</div>
        {navLinks.map((nav, index) => (
          <CustomLink to={`/${nav.id}`} key={nav.id} onClick={() => setActive(nav.title)}>
            <ul className={`flex gap-1 items-center py-2 m-1 p-4 max-sm:p-1 cursor-pointer ${darkMode ? "hover:bg-[#49425778] text-white" : "light-mode hover:bg-zinc-100 "}`}>
              {React.cloneElement(nav.icon, {
                className: `${active === nav.title ? darkMode ? "text-white" : "light-mode text-black" : "text-slate-500"} ${nav.icon.props.className}`
              })}
              <li pathname='iTrood Shop' className={`text-base font-medium leading-6 self-stretch max-md:text-[14px] max-sm:hidden ${active === nav.title ? "font-black" : "text-slate-500"}`}>
                {nav.title}
              </li>
            </ul>
          </CustomLink>
        ))}
      </div>
      <div>
        {userId !== 'null' && !isSubscriber && (
          <div
            className={`animate-pulse cursor-pointer py-2 flex gap-2 justify-center items-center text-center mt-auto rounded-t-xl ${darkMode ? "bg-[#ff0000] border hover:bg-[#ba0000] text-[#ffffff]" : "light-mode text-[#ffffff] bg-[#ff0000] hover:bg-[#c60000]"}`}
            onClick={openModal}
          >
            <div className='max-sm:hidden'>Subscribe</div>
          </div>
        )}
        {userId !== 'null' ? (
          <div className={`py-2 flex gap-2 justify-center items-center text-center mt-auto rounded-b-xl max-sm:text-[10px] ${darkMode ? "bg-white border hover:bg-slate-200 text-[#000000]" : "light-mode text-[#ffffff] bg-black hover:bg-zinc-900"}`} onClick={handleLogout}>
            <BiLogOutCircle size={20} />
            <div className='max-sm:hidden'>Logout</div>
          </div>
        ) : (
          <div className={`cursor-pointer py-2 flex gap-2 justify-center items-center text-center mt-auto rounded-b-xl max-sm:text-[10px] ${darkMode ? "bg-white border hover:bg-slate-200 text-[#000000]" : "light-mode text-[#ffffff] bg-black hover:bg-zinc-900"}`} onClick={handleLogin}>
            <BiLogInCircle size={20} />
            <div className='max-sm:hidden'>Login</div>
          </div>
        )}
      </div>
    </div>
  );
};

export const navLinks = [
  // {
  //   id: "",
  //   title: "Dashboard",
  //   icon: <MdSpaceDashboard />
  // },
  {
    id: "",
    title: "Home",
    icon: <MdLocalMovies />
  },
  {
    id: "store",
    title: "My Movies",
    icon: <MdVideoLibrary />
  },
  {
    id: "producers",
    title: "DJs | Producers",
    icon: <FaUsers />
  },
  // {
  //   id: "create",
  //   title: "Create",
  //   icon: <FaRegSquarePlus />
  // },
  // {
  //   id: "new-release",
  //   title: "New Release",
  //   icon: <MdOutlineMovieFilter />
  // },
  // {
  //   id: "posts",
  //   title: "Posts",
  //   icon: <BsFilePost />
  // },
];

function CustomLink({ to, children, ...props }) {
  return (
    <li>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export default SideBar;